<div class="flexbox direction-column">
  <eule-general-title [title]="'Projektliste'">
    <div class="flexbox smaller-gap align-center">
      <button id="project-list_newProject" mat-flat-button color="primary" (click)="newProject()">
        <mat-icon>add</mat-icon>
        Projekt
      </button>

      <div class="v-divider"></div>

      <eule-search-bar id="search-field" [(search)]="filterValue" (searchChange)="applyFilter($event)"></eule-search-bar>
    </div>
  </eule-general-title>

  <mat-card class="custom-card">
    <mat-card-content class="small-padding">
      @if (isLoading$ | async) {
        <div class="loading-overlay covering h-100-percent card-background">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      }
      <table mat-table [dataSource]="dataSource" matSort class="differ-rows row-hover pointer">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr.</th>
          <td mat-cell *matCellDef="let row">{{ row.number }}</td>
        </ng-container>
        <ng-container matColumnDef="systemType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Zertifizierungstyp</th>
          <td mat-cell *matCellDef="let row">
            @if (row['resolvedDgnbSystem'] && row['resolvedDgnbSystem']['organizationType'] && row['resolvedDgnbSystem']['name']) {
              <span [matTooltip]="row['resolvedDgnbSystem']['name']">{{ row['resolvedDgnbSystem']['organizationType'] }}</span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="projectOwner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Verantwortlicher</th>
          <td mat-cell *matCellDef="let row">{{ row.projectOwner }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let row">
            {{ row.leistungsPhasen[0] ? (row.leistungsPhasen[0] | date) : '' }} - {{ row.handoverDate ? (row.handoverDate | date) : '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="addressCity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Standort</th>
          <td mat-cell *matCellDef="let row">{{ row.addressCity }}</td>
        </ng-container>
        <ng-container matColumnDef="usageProfiles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nutzungsprofile</th>
          <td mat-cell *matCellDef="let row">
            <div class="flexbox align-center xs-gap usage-profiles">
            @for (usageProfile of row.usageProfileKeys; track usageProfile; let index = $index) {
                <div class="usage-profile-id custom-icon accent-bg leaf-bg"
                     [matTooltip]="getUsageProfileEnumValue(usageProfile)">
                  {{ getUsageProfileEnumValue(usageProfile) | slice:0:2 }}
                </div>
              }
            </div>
          </td>
        </ng-container>
<!--        <ng-container matColumnDef="createTime">-->
<!--          <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt</th>-->
<!--          <td mat-cell *matCellDef="let row">-->
<!--            @if(row.createTime) {-->
<!--              {{ row.createTime | date: 'dd.MM.yyyy' }}-->
<!--            }-->
<!--          </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="updateTime">-->
<!--          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert</th>-->
<!--          <td mat-cell *matCellDef="let row">-->
<!--            @if(row.updateTime) {-->
<!--              {{ row.updateTime | date: 'dd.MM.yyyy' }}-->
<!--            }-->
<!--          </td>-->
<!--        </ng-container>-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickProjekt(row)"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Keine Daten vorhanden</td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="(userService.euleUser$ | async)?.pagination"
        (page)="onChangePageSize($event.pageSize)"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
