<h2 mat-dialog-title>Feedback geben</h2>
<mat-dialog-content>
  <div id="indicator-dialog-container">
    <form [formGroup]="feedbackForm">
      <div class="col">
        <div class="row">
          <mat-form-field class="w-100-percent">
            <mat-label>Ihre Rückmeldung ans uns</mat-label>
            <textarea
              matInput
              formControlName="description"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="10"
              cdkAutosizeMaxRows="20"
            ></textarea>
            @if (feedbackForm.get('description')?.hasError('required')) {
            <mat-error>Dieses Feld muss einen Wert enthalten</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="closeDialog(false)">Abbrechen</button>
  <button mat-flat-button color="primary" [disabled]="!feedbackForm.dirty || !feedbackForm.valid" (click)="closeDialog(true)">Abschicken</button>
</mat-dialog-actions>
