@for (certificationDescriptionFormGroup of certificationDescriptionFormArray.controls; track certificationDescriptionFormGroup) {
<eule-certification-description-group [certificationDescriptionFormGroup]="certificationDescriptionFormGroup">
  @switch ($index) { @case (0) { @if($index === 0){<ng-container *ngTemplateOutlet="generalDataTemplate"></ng-container>} } @case (1) {
  <ng-container *ngTemplateOutlet="systemDataTemplate"></ng-container>
  } @case (2) {
  <ng-container *ngTemplateOutlet="euTaxonomyDataTemplate"></ng-container>
  } @case (3) {
  <ng-container *ngTemplateOutlet="qngDataTemplate"></ng-container>
  }}
</eule-certification-description-group>
}

<ng-template #generalDataTemplate>
  <div [formGroup]="certificationFormGroup" class="mat-elevation-z4">
    <div class="col">
      <div class="row">
        <!-- lifeCyclePhase cannot be changed! Needs to be disabled -->
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Bauphase</mat-label>
          <mat-select formControlName="lifeCyclePhase">
            @for (phase of lifeCyclePhaseEnum | keyvalue; track phase) {
            <mat-option [value]="phase.key">{{ phase.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label>Mischnutzung</mat-label>
        <mat-slide-toggle formControlName="hasMixedUsageProfiles" #mixedUsageProfiles></mat-slide-toggle>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['hasMixedUsageProfiles']"></mat-icon>
      </div>

      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Zertifizierungsversion</mat-label>
          <mat-select formControlName="certificationVersion">
            @for (certificationVersion of certificationVersionEnum | keyvalue; track certificationVersion) {
            <mat-option [value]="certificationVersion.key">{{ certificationVersion.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="width-100 smaller-font">
          <mat-label>Einreichdatum</mat-label>
          <input matInput formControlName="submissionDate" [matDatepicker]="submissionDatePicker" />
          <mat-datepicker-toggle matIconSuffix [for]="submissionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #submissionDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>QNG</mat-label>
          <mat-select formControlName="qng">
            @for (qng of booleanEnum | keyvalue; track qng) {
            <mat-option [value]="qng.key">{{ qng.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Eu-Taxonomie</mat-label>
          <mat-select formControlName="euTaxonomy">
            @for (euTaxonomy of booleanEnum | keyvalue; track euTaxonomy) {
            <mat-option [value]="euTaxonomy.key">{{ euTaxonomy.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #systemDataTemplate>
  <h4>Hier kommten die System Daten</h4>
</ng-template>
<ng-template #euTaxonomyDataTemplate>
  <h4>Hier kommten die EU-Taxonomie Daten</h4>
</ng-template>
<ng-template #qngDataTemplate>
  <h4>Hier kommten die QNG Daten</h4>
</ng-template>
