import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Project } from '@eeule/eeule-shared/src/types';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { ProjectService } from '../../../../core/services/project.service';
export interface IPhase {
  title: string;
  startDate: number | null; // Timestamp
}
@Component({
  selector: 'eule-tab-leistungsphasen',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  templateUrl: './tab-leistungsphasen.component.html',
  styleUrl: './tab-leistungsphasen.component.scss',
})
export class TabLeistungsphasenComponent extends BaseComponent implements OnInit {
  @Input() leistungsPhasenFormGroup!: FormGroup;

  public staticLeistungsphasen: Array<IPhase> = [
    { title: 'Lph1: Grundlagenermittlung', startDate: null },
    { title: 'Lph2: Vorplanung', startDate: null },
    { title: 'Lph3: Entwurfsplanung', startDate: null },
    { title: 'Lph4: Genehmigungsplanung', startDate: null },
    { title: 'Lph5: Ausführungsplanung', startDate: null },
    { title: 'Lph6: Vorbereitung der Vergabe', startDate: null },
    { title: 'Lph7: Mitwirkung der Vergabe', startDate: null },
    { title: 'Lph8: Objektüberwachung', startDate: null },
    { title: 'Lph9: Objektbetreuung', startDate: null },
  ];
  get leistungsPhasen() {
    return this.leistungsPhasenFormGroup.get('leistungsPhasen') as FormArray;
  }

  public constructor(public projectService: ProjectService) {
    super();
  }

  ngOnInit() {
    this.projectService.project$.subscribe((project: Project | null) => {
      if (project) {
        this.projectService.projectUser$.pipe().subscribe(user => {
          if (user) {
            this._setListenerOnHandoverDateAndValidateLeistungsphasen();
          }
        });
      }
    });
  }

  private _setListenerOnHandoverDateAndValidateLeistungsphasen(): void {
    this.leistungsPhasenFormGroup.get('handoverDate')!.valueChanges.subscribe(() => {
      this.leistungsPhasen.controls.forEach(() => {
        this.leistungsPhasen.updateValueAndValidity();
      });
    });
    this.leistungsPhasenFormGroup.markAllAsTouched();
  }
}
