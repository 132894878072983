<ng-container [formGroup]="descriptionGroup">
  <div class="mat-elevation-z4">
    <div class="row tab-content">
      <h2>{{ title === 'mainUsage' ? 'Hauptnutzung' : title === 'subordinateUsage' ? 'Untergeordnete Nutzung' : 'Nebennutzung' }}</h2>
    </div>

    <div class="row">
      <div class="col padding-right">
        @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === usageProfileType!.value) {
        {{ usageProfileEnum[profileType.name] }}
        }}
        <ng-container formArrayName="descriptions">
          @for (description of descriptions.controls; track description) {
          <ng-container [formGroup]="getDescriptionGroupAt($index)">
            <mat-form-field class="w-100-percent smaller-font">
              <mat-label>{{ getDescriptionTitleAt($index) }}</mat-label>
              <textarea matInput formControlName="description" rows="7"></textarea>
            </mat-form-field>
          </ng-container>
          }
        </ng-container>
      </div>
      <div class="col padding-right">
        <h3>Erforderliche Nachweise</h3>
        <ng-container formArrayName="attestations">
          @for (attestation of attestations.controls; track attestation) {
          <ng-container [formGroup]="getAttestationGroupAt($index)">
            <mat-checkbox formControlName="checked" color="primary">
              <span class="secondary-text">{{ getAttestationDescriptionAt($index) }}</span>
            </mat-checkbox>
          </ng-container>
          }
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
