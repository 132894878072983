import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../../services/auth-christian/auth.service';

/**
 * Guard to protect routes based on authentication status.
 * @returns {Observable<boolean>} An observable indicating whether the route should be activated.
 */
export const _authGuard: CanActivateFn = (): Observable<boolean> => {
  const _authService: AuthService = inject(AuthService);
  const _router: Router = inject(Router);
  return _authService.isAuthenticated().pipe(
    take(1),
    map(isAuthenticated => {
      if (!isAuthenticated) {
        // Redirect to the login page or any other route
        setTimeout(() => {
          _router.navigate(['/login']).catch(error => console.error('error navigating back to login:', error));
        });

        return false;
      }
      if (isAuthenticated && !getAuth().currentUser?.emailVerified) {
        _router.navigate(['/verify']).catch(error => console.error('error navigating back to login:', error));
      }
      return true;
    })
  );
};
