import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommaDecimalInputComponent } from '../../../../../core/components/decimal-comma/decimal-comma.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { AttestationFormGroup, DescriptionFormGroup, DescriptionGroupFormGroup } from '../../project-info-page/project-info-page.component';

@Component({
  selector: 'eule-images-description-group',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatCheckbox,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './images-description-group.component.html',
  styleUrl: './images-description-group.component.scss',
})
export class ImagesDescriptionGroupComponent {
  @Input() imagesDescriptionFormGroup!: any | FormGroup<DescriptionGroupFormGroup>;

  get title(): FormControl<string | null> {
    return this.imagesDescriptionFormGroup.get('title').value || '';
  }
  get attestations(): FormArray<FormGroup<AttestationFormGroup>> {
    return this.imagesDescriptionFormGroup.get('attestations') as FormArray<FormGroup<AttestationFormGroup>>;
  }
  get imagesDescriptions(): FormArray<FormGroup<DescriptionFormGroup>> {
    return this.imagesDescriptionFormGroup.get('descriptions') as FormArray<FormGroup<DescriptionFormGroup>>;
  }

  public constructor() {}

  public getAttestationGroupAt(index: number): FormGroup<AttestationFormGroup> {
    return this.attestations.at(index);
  }
  public getDescriptionGroupAt(index: number): FormGroup<DescriptionFormGroup> {
    return this.imagesDescriptions.at(index);
  }
  public getDescriptionTitleAt(index: number): string {
    return this.imagesDescriptions.at(index)!.get('title')?.value || '';
  }
  public getAttestationDescriptionAt(index: number): string {
    return this.attestations.at(index)!.get('description')?.value || '';
  }
}
