import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { catchError, map, of, switchMap, take, throwError } from 'rxjs';
import { FeedbackService } from '../../core/services/feedback.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import {
  BugReportDialogComponent,
  BugReportDialogComponentConfig,
  BugReportDialogComponentResult,
} from '../components/bug-report-dialog/bug-report-dialog.component';
import { FeedbackDialogComponent, FeedbackDialogComponentConfig, FeedbackDialogComponentResult } from '../components/feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'eule-feedback',
  standalone: true,
  imports: [MatButtonModule, MatCardModule, MatIconModule, MatTooltip],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent {
  public constructor(private _dialog: MatDialog, private _snackbarService: SnackbarService, private _feedbackService: FeedbackService) {}

  public openFeedback() {
    const dialogRef = this._dialog.open<FeedbackDialogComponent, FeedbackDialogComponentConfig, FeedbackDialogComponentResult | undefined>(
      FeedbackDialogComponent,
      {
        data: {},
        width: '640px',
        maxWidth: '70vw',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(result => {
          if (!result) return of(null);
          return this._feedbackService.setFeedback(result).pipe(
            catchError(error => throwError(() => error)),
            map(() => true)
          );
        }),
        catchError(error => {
          this._snackbarService.showErrorMessage('Beim Versenden der Rückmeldung ist ein Fehler aufgetreten.');
          return throwError(() => error);
        }),
        take(1)
      )
      .subscribe((feedbackWasSubmitted: boolean | null) => {
        if (feedbackWasSubmitted) {
          this._snackbarService.showMessage('Ihre Rückmeldung wurde versendet. Vielen Dank für Ihr Feedback!', 'success');
        }
      });
  }

  public openBug() {
    const dialogRef = this._dialog.open<BugReportDialogComponent, BugReportDialogComponentConfig, BugReportDialogComponentResult | undefined>(
      BugReportDialogComponent,
      {
        data: {},
        width: '660px',
        maxWidth: '70vw',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(result => {
          if (!result) return of(null);
          return this._feedbackService.setBugReport(result).pipe(
            catchError(error => throwError(() => error)),
            map(() => true)
          );
        }),
        catchError(error => {
          this._snackbarService.showErrorMessage('Die Fehlermeldung konnte nicht versendet werden.');
          return throwError(() => error);
        }),
        take(1)
      )
      .subscribe((feedbackWasSubmitted: boolean | null) => {
        if (feedbackWasSubmitted) {
          this._snackbarService.showMessage('Ihre Fehlermeldung wurde versendet. Vielen Dank für Ihr Feedback!', 'success');
        }
      });
  }
}
