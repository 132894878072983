import { AsyncPipe, CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { StorageService } from '../../core/services/storage.service';
import { ProjectService } from '../../core/services/project.service';
import { BehaviorSubject, switchMap } from 'rxjs';

@Component({
  selector: 'eule-home',
  standalone: true,
  imports: [AsyncPipe, CommonModule, MatCardModule, GeneralTitleComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  public storageProjectSize$ = new BehaviorSubject(0);
  public constructor(private _projectService: ProjectService, private _storageService: StorageService) {
    this._projectService.project$.pipe(switchMap(project => this._storageService.calculateFolderSize(`projects/${project?.id}`))).subscribe(num => {
      this.storageProjectSize$.next(Math.round(num / 1024)); // Result in KiloByte
    });
  }
}
