import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { User } from '@eeule/eeule-shared/src/types';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { AuthService } from '../../../core/services/auth-christian/auth.service';
import { UserService } from '../../../core/services/user.service';
interface ProfileInformationForm {
  title: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  company: FormControl<string | null>;
}
@Component({
  selector: 'eule-profile-information',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './profile-information.component.html',
  styleUrl: './profile-information.component.scss',
})
export class ProfileInformationComponent extends BaseComponent {
  public profileForm: FormGroup<ProfileInformationForm> = this._formBuilder.group({
    title: [''],
    email: [{ value: '', disabled: true }, [Validators.required]],
    phone: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    company: [''],
  });
  public editMode: boolean = false;
  public user = this._authService.getAuth().currentUser;

  public constructor(
    private _analyticsService: AnalyticsService,
    public userService: UserService,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    super();
    this._analyticsService.sendPageView('profile-information-page');
    this._createForm();
  }

  public save() {
    this._analyticsService.sendEvent('button_click', { label: 'profile-information_button-save' });
    this.editMode = false;
    this.userService.updateUser(this.user!.uid, this.profileForm.value as Partial<User>);
  }

  public edit() {
    this.editMode = true;
  }

  private _createForm() {
    this.userService.euleUser$.pipe(takeUntil(this.stop$)).subscribe((euleUser: User | null) => {
      if (euleUser && this.user) {
        this.profileForm = this._formBuilder.group({
          title: [euleUser.title || ''],
          email: [{ value: this.user?.email, disabled: true }, [Validators.required]],
          phone: [euleUser.phone || ''],
          firstName: [euleUser.firstName || '', [Validators.required, Validators.minLength(3)]],
          lastName: [euleUser.lastName || '', [Validators.required, Validators.minLength(3)]],
          company: [euleUser.company || ''],
        });
      }
    });
  }
}
