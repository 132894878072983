import { Injectable } from '@angular/core';
import {
  AuthCredential,
  User,
  UserCredential,
  browserSessionPersistence,
  getAuth,
  reauthenticateWithCredential,
  setPersistence,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { Observable, from } from 'rxjs';

/**
 *
 * @deprecated
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  /**
   * FIXME: Mit Christian besprechen, damit es nur einen AuthService gibt
   * @depcrecated
   *
   * @param {(string | null | undefined)} email
   * @param {(string | null | undefined)} password
   * @returns {(Promise<void | UserCredential>)}
   *
   * @memberOf AuthService
   */
  public login(email: string | null | undefined, password: string | null | undefined): Promise<void | UserCredential> {
    return setPersistence(getAuth(), browserSessionPersistence)
      .then(() => {
        if (!email || !password) {
          throw new Error('Email or Password not provided');
        }
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        return signInWithEmailAndPassword(getAuth(), email, password);
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        throw new Error('Error' + errorCode + errorMessage);
      });
  }

  public reauthenticateEuleUser(credential: AuthCredential): Observable<UserCredential> {
    const _currentuser: User | null = getAuth().currentUser;
    if (!_currentuser) {
      throw new Error('No Firebase Auth User');
    }
    return from(reauthenticateWithCredential(_currentuser, credential));
  }

  public updateEuleUserPassword(newPassword: string): Observable<void> {
    const _currentuser: User | null = getAuth().currentUser;
    if (!_currentuser) {
      throw new Error('No Firebase Auth User');
    }
    return from(updatePassword(_currentuser, newPassword));
  }
}
