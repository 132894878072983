import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Attestation, EntityDescription, DescriptionGroup, MixedUsageProfile, Project } from '@eeule/eeule-shared/src/types';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { GeneralTitleComponent } from '../../../../core/components/general-title/general-title.component';
import { SafeHtmlPipe } from '../../../../core/pipes/safe-html.pipe';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../core/services/project.service';
import { BooleanEnum } from '../../../../enums/BooleanEnum.enum';
import { CertificationTypeEnum } from '../../../../enums/CertificationType.enum';
import { CertificationVersionEnum } from '../../../../enums/CertificationVersion.enum';
import { LifeCyclePhaseEnum } from '../../../../enums/LifeCyclePhase.enum';
import { ProjectStatusEnum } from '../../../../enums/ProjectStatus.enum';
import { UsageProfileEnum } from '../../../../enums/UsageProfile.enum';
import { TabBuildingParametersComponent } from '../tab-building-parameters/tab-building-parameters.component';
import { TabCertificationComponent } from '../tab-certification/tab-certification.component';
import { TabContactPersonsComponent } from '../tab-contact-persons/tab-contact-persons.component';
import { TabGeneralComponent } from '../tab-general/tab-general.component';
import { TabLeistungsphasenComponent } from '../tab-leistungsphasen/tab-leistungsphasen.component';
import { TabBuildingDescriptionComponent } from '../tab-building-description/tab-building-description.component';
import { TabImagesComponent } from '../tab-images/tab-images.component';
import { TabUsageProfilesComponent } from '../tab-usage-profiles/tab-usage-profiles.component';

interface ProjectForm {
  // Tab Allgemein
  general: FormGroup<GeneralFormGroup>;

  // Tab Baubeschreibung
  buildingDescription: FormGroup<TotalDescriptionGroupsFormGroup>;

  // Tab Nutzungsprofile

  // Tab Zertifizierung
  certification: FormGroup<CertificationFormGroup>;
  certificationDescription: FormGroup<TotalDescriptionGroupsFormGroup>;

  // Tab Leistungsphasen
  leistungsPhasen: FormGroup<LeistungsPhasenFormGroup>;

  // Tab BuildingParameters
  buildingParameters: FormGroup<BuildingParametersFormGroup>;

  // Tab Ansprechpartner
  contactPersons: FormGroup<ContactPersonsFormGroup>;

  // Tab Fotos
  imagesDescription: FormGroup<TotalDescriptionGroupsFormGroup>;

  dgnbSystem: FormControl<string | null>;
  status: FormControl<ProjectStatusEnum | null>;
  bgfBigger5000: FormControl<boolean | null>;
  bgfSmaller5000: FormControl<boolean | null>;
  withDeconstruction: FormControl<boolean | null>;
  withoutDeconstruction: FormControl<boolean | null>;
}

interface GeneralFormGroup {
  id: FormControl<string | null>;
  name: FormControl<string | null>;
  number: FormControl<string | null>;
  applicationNumber: FormControl<string | null>;
  costs: FormControl<number | null>;
  description: FormControl<string | null>;
  projectImagePath: FormControl<string | null>;
  addressStreet: FormControl<string | null>;
  addressNumber: FormControl<string | null>;
  addressStreetAdditionalInfo: FormControl<string | null>;
  addressCity: FormControl<string | null>;
  addressPostCode: FormControl<string | null>;
  addressState: FormControl<string | null>;
  addressCountry: FormControl<string | null>;
}

type TotalDescriptionGroupsFormGroupNames = 'buildingDescriptionGroups' | 'certificationDescriptionGroups' | 'imagesDescriptionGroups';
export type TotalDescriptionGroupsFormGroup = Partial<Record<TotalDescriptionGroupsFormGroupNames, FormArray>>;

export interface DescriptionGroupFormGroup {
  id: FormControl<string | null>;
  title: FormControl<string | null>;
  attestations: FormArray; // <AttestationFormGroup>;
  descriptions: FormArray; // <DescriptionFormGroup>;
}

export interface AttestationFormGroup {
  id: FormControl<string | null>;
  checked: FormControl<boolean>;
  description: FormControl<string | null>;
}

export interface DescriptionFormGroup {
  title: FormControl<string | null>;
  description: FormControl<string | null>;
}
interface CertificationFormGroup {
  lifeCyclePhase: FormControl<LifeCyclePhaseEnum | any | null>;
  hasMixedUsageProfiles: FormControl<boolean | null>;
  certificationType: FormControl<CertificationTypeEnum | null>;
  certificationVersion: FormControl<string | null>;
  submissionDate: FormControl<Date | null>;
  qng: FormControl<boolean | null>;
  euTaxonomy: FormControl<boolean | null>;
}

interface LeistungsPhasenFormGroup {
  leistungsPhasen: FormArray;
  handoverDate: FormControl<Date | null>;
}
interface BuildingParametersFormGroup {
  usageProfiles: FormArray;
  numberOfFloors: FormControl<number | null>;
  numberOfUndergroundFloors: FormControl<number | null>;
  numberOfParkingSpaces: FormControl<number | null>;
  numberOfResidentialUnits: FormControl<number | null>;
  numberOfWorkspaces: FormControl<number | null>;
  numberOfUsers: FormControl<number | null>;
  bwzNr: FormControl<number | null>;
  lcaClass: FormControl<string | null>;
  typeOfCommercialSpaces: FormControl<string | null>;
}

interface ContactPersonsFormGroup {
  projectOwner: FormControl<string | null>;
  creator: FormControl<string | null>;
  auditor: FormControl<string | null>;
  supporter: FormControl<string | null>;
}

export function lphDateNotBeforePreviousValidator(
  lphIndex: number,
  leistungsPhasenArray: FormArray<FormControl<number>>,
  handoverDateControl: FormControl
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    let valid: boolean = true;
    for (let i = 0; i < lphIndex; i++) {
      const element = new Date(leistungsPhasenArray.at(i)?.value).valueOf();
      if (new Date(control.value).valueOf() < element) {
        valid = false;
      }
      if (handoverDateControl.value && new Date(control.value).valueOf() > new Date(handoverDateControl.value).valueOf()) {
        valid = false;
      }
    }

    return !valid ? { lphDatebefore: true } : null;
  };
}

export interface Tooltip {
  [key: string]: string;
}
@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'eule-project-info-page',
  standalone: true,
  imports: [
    CommaDecimalInputComponent,
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    TabBuildingParametersComponent,
    TabGeneralComponent,
    TabUsageProfilesComponent,
    TabLeistungsphasenComponent,
    TabContactPersonsComponent,
    TabCertificationComponent,
    TabBuildingDescriptionComponent,
    TabImagesComponent,
  ],
  templateUrl: './project-info-page.component.html',
  styleUrl: './project-info-page.component.scss',
})
export class ProjectInfoPageComponent extends BaseComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // public isProjectLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public projectImage$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(
    './../../../../assets/images/Rendering_Mehrfamilienhaus3.jpeg'
  );

  /**
   * TODO: This should be a i18 table
   *
   * @type {Tooltip}
   * @memberOf ProjectInfoPageComponent
   */
  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
    sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
    mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
    flaechenberechnungNachDin277: `Flächenberechnung nach DIN 277
    BGF(R) = KGF(R) + NRF(R)    
    NRF(R) = NUF(R) + TF(R) + VF(R)
    BGF                Brutto-Grundfläche
    KGF                Konstruktions-Grundfläche
    NRF                Netto-Raumfläche
    NUF                Nutzungsfläche
    TF                 Technikfläche
    VF                 Verkehrsfläche`,
    bemessungsflaeche: `DGNB Bemessungsfläche
    ADGNB = NUFa - NUF a,7,4 + VFa,9,1
    ADGNB                 DGNB Bemesungsfläche
    NUFa                  Nutzungsfläche nach DIN277
    NUF a,7,4            Fahrzeugabstellfläche nach DIN 277
    VFa,9,1                Verkehrsfläche Flure und Hallen nach DIN277
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  public lifeCyclePhaseEnum: typeof LifeCyclePhaseEnum = LifeCyclePhaseEnum;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;
  public certificationTypeEnum: typeof CertificationTypeEnum = CertificationTypeEnum;
  public certificationVersionEnum: typeof CertificationVersionEnum = CertificationVersionEnum;
  public booleanEnum: typeof BooleanEnum = BooleanEnum;
  public filterValue: string = '';

  public projectForm: FormGroup<ProjectForm> = this._formBuilder.group({
    general: this._formBuilder.group<GeneralFormGroup>({
      id: this._formBuilder.control<string | null>(null),
      name: this._formBuilder.control<string | null>(null),
      number: this._formBuilder.control<string | null>(null),
      applicationNumber: this._formBuilder.control<string | null>(null),
      costs: this._formBuilder.control<number | null>(null),
      description: this._formBuilder.control<string | null>(null),
      projectImagePath: this._formBuilder.control<string | null>(null),
      addressStreet: this._formBuilder.control<string | null>(null),
      addressNumber: this._formBuilder.control<string | null>(null),
      addressStreetAdditionalInfo: this._formBuilder.control<string | null>(null),
      addressCity: this._formBuilder.control<string | null>(null),
      addressPostCode: this._formBuilder.control<string | null>(null),
      addressState: this._formBuilder.control<string | null>(null),
      addressCountry: this._formBuilder.control<string | null>(null),
    }),
    buildingDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
      buildingDescriptionGroups: this._formBuilder.array([]),
    }),

    buildingParameters: this._formBuilder.group<BuildingParametersFormGroup>({
      usageProfiles: this._formBuilder.array([]),
      numberOfFloors: this._formBuilder.control<number | null>(null),
      numberOfUndergroundFloors: this._formBuilder.control<number | null>(null),
      numberOfParkingSpaces: this._formBuilder.control<number | null>(null),
      numberOfResidentialUnits: this._formBuilder.control<number | null>(null),
      numberOfWorkspaces: this._formBuilder.control<number | null>(null),
      numberOfUsers: this._formBuilder.control<number | null>(null),
      bwzNr: this._formBuilder.control<number | null>(null),
      lcaClass: this._formBuilder.control<string | null>(null),
      typeOfCommercialSpaces: this._formBuilder.control<string | null>(null),
    }),

    // Tab Zertifizierung
    certification: this._formBuilder.group<CertificationFormGroup>({
      lifeCyclePhase: this._formBuilder.control<LifeCyclePhaseEnum | null>({ value: null, disabled: true }),
      hasMixedUsageProfiles: this._formBuilder.control<boolean | null>(false),
      certificationType: this._formBuilder.control<CertificationTypeEnum | null>(null),
      certificationVersion: this._formBuilder.control<string | null>(null),
      submissionDate: this._formBuilder.control<Date | null>(null),
      qng: this._formBuilder.control<boolean>(false),
      euTaxonomy: this._formBuilder.control<boolean>(false),
    }),
    certificationDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
      certificationDescriptionGroups: this._formBuilder.array([]),
    }),

    // Tab Leistungsphasen
    leistungsPhasen: this._formBuilder.group<LeistungsPhasenFormGroup>({
      leistungsPhasen: this._formBuilder.array([]),
      handoverDate: this._formBuilder.control<Date | null>(null),
    }),

    // Tab Ansprechpartner
    contactPersons: this._formBuilder.group<ContactPersonsFormGroup>({
      projectOwner: this._formBuilder.control<string | null>(null),
      creator: this._formBuilder.control<string | null>(null),
      auditor: this._formBuilder.control<string | null>(null),
      supporter: this._formBuilder.control<string | null>(null),
    }),

    imagesDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
      imagesDescriptionGroups: this._formBuilder.array([]),
    }),

    dgnbSystem: this._formBuilder.control<string | null>(null),
    status: this._formBuilder.control<ProjectStatusEnum | null>(null),
    bgfBigger5000: this._formBuilder.control<boolean | null>(null),
    bgfSmaller5000: this._formBuilder.control<boolean | null>(null),
    withDeconstruction: this._formBuilder.control<boolean | null>(null),
    withoutDeconstruction: this._formBuilder.control<boolean | null>(null),
  });

  get leistungsPhasen() {
    return this.leistungsPhasenFormGroup!.get('leistungsPhasen') as FormArray;
  }

  get generalFormGroup(): FormGroup<GeneralFormGroup> {
    return this.projectForm.get('general') as FormGroup<GeneralFormGroup>;
  }
  get totalBuildingDescriptionFormGroup(): FormGroup<TotalDescriptionGroupsFormGroup> {
    return this.projectForm.get('buildingDescription') as FormGroup<TotalDescriptionGroupsFormGroup>;
  }
  get buildingParametersFormGroup(): FormGroup<BuildingParametersFormGroup> {
    return this.projectForm.get('buildingParameters') as FormGroup<BuildingParametersFormGroup>;
  }
  get usageProfilesFormArray(): FormArray {
    return this.buildingParametersFormGroup.get('usageProfiles') as FormArray;
  }

  get certificationFormGroup(): FormGroup<CertificationFormGroup> {
    return this.projectForm.get('certification') as FormGroup<CertificationFormGroup>;
  }
  get totalCertificationDescriptionFormGroup(): FormGroup<TotalDescriptionGroupsFormGroup> {
    return this.projectForm.get('certificationDescription') as FormGroup<TotalDescriptionGroupsFormGroup>;
  }

  get leistungsPhasenFormGroup() {
    return this.projectForm.get('leistungsPhasen') as FormGroup<LeistungsPhasenFormGroup>;
  }
  get contactPersonsFormGroup() {
    return this.projectForm.get('contactPersons') as FormGroup<ContactPersonsFormGroup>;
  }
  get totalImagesDescriptionFormGroup(): FormGroup<TotalDescriptionGroupsFormGroup> {
    return this.projectForm.get('imagesDescription') as FormGroup<TotalDescriptionGroupsFormGroup>;
  }

  public constructor(public projectService: ProjectService, private _formBuilder: FormBuilder, public analyticsService: AnalyticsService) {
    super();
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this.projectService.project$.subscribe((project: Project | null) => {
      if (project) {
        this.projectService.projectUser$.pipe().subscribe(user => {
          if (user) {
            this.isLoading$.next(false);
            // FIXME: This is a really bad solution. ProjectUser should always be set before a project can be opened.
            // Loading the ProjectUser therefore needs to be awaited in projectservice
            // FIXME: this also causes the bug, that a switch between logged in users is not propagated properly. IMPORTANT!
            this.projectForm = this._buildFormFromData(project);
            // this.isProjectLoading$.next(false);

            this.leistungsPhasen.controls.forEach((control, index) =>
              control.setValidators(
                lphDateNotBeforePreviousValidator(index, this.leistungsPhasen, this.leistungsPhasenFormGroup!.get('handoverDate')! as FormControl)
              )
            );
            this.leistungsPhasen.valueChanges.subscribe(() => {
              this.leistungsPhasen.controls.forEach(lph => {
                lph.updateValueAndValidity({
                  emitEvent: false,
                });
              });
            });
          }
        });
      }
    });
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue.trim().toLowerCase();
  }

  public update() {
    this.analyticsService.sendEvent('button_click', {
      label: 'project-info-page_button_save',
    });
    if (this.projectService.project$.value?.id) {
      const _updatedProject: Project = {
        ...this.generalFormGroup.getRawValue(),
        ...this.totalBuildingDescriptionFormGroup.getRawValue(),
        ...this.buildingParametersFormGroup.getRawValue(),
        ...this.certificationFormGroup.getRawValue(),
        ...this.totalCertificationDescriptionFormGroup.getRawValue(),
        ...this.leistungsPhasenFormGroup.getRawValue(),
        ...this.contactPersonsFormGroup.getRawValue(),
        ...this.totalImagesDescriptionFormGroup.getRawValue(),

        // Format Moment to timestamp
        leistungsPhasen: this.leistungsPhasen.controls.map(control => (control.value ? control.value.valueOf() : null)),
        handoverDate: this.leistungsPhasenFormGroup.get('handoverDate')?.value ? this.leistungsPhasenFormGroup.get('handoverDate')!.value!.valueOf() : null,
        submissionDate: this.certificationFormGroup.get('submissionDate')?.value ? this.certificationFormGroup.get('submissionDate')!.value!.valueOf() : null,

        dgnbSystem: this.projectForm.get('dgnbSystem')?.value,
        status: this.projectForm.get('status')?.value,
        bgfBigger5000: this.projectForm.get('bgfBigger5000')?.value,
        bgfSmaller5000: this.projectForm.get('bgfSmaller5000')?.value,
        withDeconstruction: this.projectForm.get('withDeconstruction')?.value,
        withoutDeconstruction: this.projectForm.get('withoutDeconstruction')?.value,
      } as Project;

      // make sure no undefined fields are beeing updated. "null" is okay - just "undefined" is not allowed
      Object.keys(_updatedProject).forEach((key: string) => {
        const _key: keyof Project = key as keyof Project;
        if (_updatedProject[_key] === undefined) {
          delete _updatedProject[_key];
        }
      });

      this.isLoading$.next(true);
      this.projectService.updateProject(this.projectService.project$.value?.id, _updatedProject).subscribe(() => this.isLoading$.next(false));
    } else {
      throw new Error('Project id does not exist');
    }
  }

  public scenario() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _buildFormFromData(project: Project): FormGroup<ProjectForm> {
    const numRegex = /^-?\d*[.,]?\d{0,2}$/;
    const _form = this._formBuilder.group({
      // -- Tab Allgemein
      general: this._formBuilder.group<GeneralFormGroup>({
        id: this._formBuilder.control(project.id || null),
        // // -- Tab Allgemein
        name: this._formBuilder.control(project.name || null),
        number: this._formBuilder.control(project.number || null),
        applicationNumber: this._formBuilder.control(project.applicationNumber || null),
        costs: this._formBuilder.control(project.costs || null),
        description: this._formBuilder.control(project.description || null),
        projectImagePath: this._formBuilder.control(project.projectImagePath || null),
        addressStreet: this._formBuilder.control(project.addressStreet || null),
        addressNumber: this._formBuilder.control(project.addressNumber || null),
        addressStreetAdditionalInfo: this._formBuilder.control(project.addressStreetAdditionalInfo || null),
        addressCity: this._formBuilder.control(project.addressCity || null),
        addressPostCode: this._formBuilder.control(project.addressPostCode || null),
        addressState: this._formBuilder.control(project.addressState || null),
        addressCountry: this._formBuilder.control(project.addressCountry || null),
      }),

      // -- Tab Baubeschreibung
      buildingDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
        buildingDescriptionGroups: this._formBuilder.array(
          project.buildingDescriptionGroups
            ? project.buildingDescriptionGroups!.map((group: DescriptionGroup) => this._createEntityDescriptionGroupFormGroup(group))
            : []
        ),
      }),

      // -- Tab Nutzugsprofile (same form data as Gebäudeparameter)

      // -- Tab Zertifizierung
      certification: this._formBuilder.group<CertificationFormGroup>({
        lifeCyclePhase: this._formBuilder.control({ value: project.lifeCyclePhase || null, disabled: true }),
        hasMixedUsageProfiles: this._formBuilder.control({ value: project.usageProfiles.length > 1, disabled: true }),
        certificationType: this._formBuilder.control(project.certificationType || null),
        certificationVersion: this._formBuilder.control({ value: project.certificationVersion || null, disabled: true }),
        submissionDate: this._formBuilder.control(project.submissionDate ? new Date(project.submissionDate) : null),
        qng: this._formBuilder.control(project.qng || false),
        euTaxonomy: this._formBuilder.control(project.euTaxonomy || false),
      }),

      certificationDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
        certificationDescriptionGroups: this._formBuilder.array(
          project.certificationDescriptionGroups
            ? project.certificationDescriptionGroups!.map((group: DescriptionGroup) => this._createEntityDescriptionGroupFormGroup(group))
            : []
        ),
      }),

      // -- Tab Leistungsphasen
      leistungsPhasen: this._formBuilder.group<LeistungsPhasenFormGroup>({
        leistungsPhasen: this._formBuilder.array(project.leistungsPhasen!.map((phase: number) => this._formBuilder.control(phase ? new Date(phase) : null))),
        handoverDate: this._formBuilder.control(project.handoverDate ? new Date(project.handoverDate) : null),
      }),

      // -- Tabs Gebäudeparameter & Nutzungsprofile (share the same data)
      buildingParameters: this._formBuilder.group<BuildingParametersFormGroup>({
        usageProfiles: this._formBuilder.array(
          project.usageProfiles.map((profile: MixedUsageProfile) => {
            const _adgnb = (Number(profile.nuf) || 0) - (Number(profile.nfVehicle) || 0) + (Number(profile.vfHallCorridor) || 0);
            const _nrf = Number(profile.nuf) + Number(profile.tf) + Number(profile.vf);
            const _bgf = Number(profile.kgf) + Number(_nrf);
            const _descriptionGroupFormGroup = profile.descriptionGroup
              ? this._createEntityDescriptionGroupFormGroup(profile.descriptionGroup)
              : this._formBuilder.group({});

            return this._formBuilder.group({
              isMainUsage: this._formBuilder.control(profile.isMainUsage),
              usageProfile: this._formBuilder.control({ value: profile.usageProfile, disabled: true }),
              descriptionGroup: _descriptionGroupFormGroup,
              bgf: this._formBuilder.control({ value: Math.round(_bgf * 100) / 100 || 0.0, disabled: true }), // Combined Value of kgf + nrf
              bri: this._formBuilder.control(profile.bri || 0.0, Validators.pattern(numRegex)),
              nrf: this._formBuilder.control({ value: Math.round((_nrf ?? 0) * 100) / 100 || 0.0, disabled: true }), // Combined Value of nuf + tf + vf
              nuf: this._formBuilder.control(profile.nuf || 0.0, Validators.pattern(numRegex)),
              tf: this._formBuilder.control(profile.tf || 0.0, Validators.pattern(numRegex)),
              vf: this._formBuilder.control(profile.vf || 0.0, Validators.pattern(numRegex)),
              kgf: this._formBuilder.control(profile.kgf || 0.0, Validators.pattern(numRegex)),
              adgnb: this._formBuilder.control({ value: Math.round(_adgnb * 100) / 100 || 0.0, disabled: true }), // Combined Value of NUF -nfVehicle + vfHallCorridor
              nfVehicle: this._formBuilder.control(profile.nfVehicle || 0.0, Validators.pattern(numRegex)),
              vfHallCorridor: this._formBuilder.control(profile.vfHallCorridor || 0.0, Validators.pattern(numRegex)),
              percentage: this._formBuilder.control({ value: profile.percentage || 0.0, disabled: true }), // Percentage of bgf in correlation to total bgf
            });
          })
        ),
        numberOfFloors: this._formBuilder.control(project.numberOfFloors || null),
        numberOfUndergroundFloors: this._formBuilder.control(project.numberOfUndergroundFloors || null),
        numberOfParkingSpaces: this._formBuilder.control(project.numberOfParkingSpaces || null),
        numberOfResidentialUnits: this._formBuilder.control(project.numberOfResidentialUnits || null),
        numberOfWorkspaces: this._formBuilder.control(project.numberOfWorkspaces || null),
        numberOfUsers: this._formBuilder.control(project.numberOfUsers || null),
        bwzNr: this._formBuilder.control(project.bwzNr || null),
        lcaClass: this._formBuilder.control(project.lcaClass || null),
        typeOfCommercialSpaces: this._formBuilder.control(project.typeOfCommercialSpaces || null),
      }),

      // -- Tab Ansprechpartner
      contactPersons: this._formBuilder.group<ContactPersonsFormGroup>({
        projectOwner: this._formBuilder.control({
          value: project.projectOwner,
          disabled: this.projectService.projectUser$.value?.id === project.projectOwner ? false : true,
        }),
        // projectOwner: this._formBuilder.control({ value: project.projectOwner, disabled: true }),
        creator: this._formBuilder.control(project.creator || null),
        auditor: this._formBuilder.control(project.auditor || null),
        supporter: this._formBuilder.control(project.supporter || null),
      }),

      // -- Tab Fotos
      imagesDescription: this._formBuilder.group<TotalDescriptionGroupsFormGroup>({
        imagesDescriptionGroups: this._formBuilder.array(
          project.imagesDescriptionGroups
            ? project.imagesDescriptionGroups!.map((group: DescriptionGroup) => this._createEntityDescriptionGroupFormGroup(group))
            : []
        ),
      }),

      dgnbSystem: this._formBuilder.control<string | null>(project.dgnbSystem || null),
      status: this._formBuilder.control<ProjectStatusEnum | null>(project.status || null),
      bgfBigger5000: this._formBuilder.control<boolean | null>(project.bgfBigger5000 || null),
      bgfSmaller5000: this._formBuilder.control<boolean | null>(project.bgfSmaller5000 || null),
      withDeconstruction: this._formBuilder.control<boolean | null>(project.withDeconstruction || null),
      withoutDeconstruction: this._formBuilder.control<boolean | null>(project.withoutDeconstruction || null),
    });

    return _form as FormGroup<ProjectForm>;
  }

  private _createEntityDescriptionGroupFormGroup(group: DescriptionGroup): FormGroup<DescriptionGroupFormGroup> {
    return this._formBuilder.group<DescriptionGroupFormGroup>({
      id: this._formBuilder.control<string | null>(group.id || null),
      title: this._formBuilder.control<string | null>(group.title || null),
      attestations: this._formBuilder.array(
        group.attestations ? group.attestations!.map((attestation: Attestation) => this._createEntityDescriptionGroupAttestationFormGroup(attestation)) : []
      ),
      descriptions: this._formBuilder.array(
        group.descriptions
          ? group.descriptions!.map((buildingDescription: EntityDescription) => this._createEntityDescriptionGroupDescriptionFormGroup(buildingDescription))
          : []
      ),
    });
  }

  private _createEntityDescriptionGroupAttestationFormGroup(attestation: Attestation): FormGroup<AttestationFormGroup> {
    return this._formBuilder.group<AttestationFormGroup>({
      id: this._formBuilder.control<string | null>(attestation.id || null),
      checked: this._formBuilder.control<boolean>(attestation.checked, {
        nonNullable: true,
      }),
      description: this._formBuilder.control<string | null>(attestation.description || null),
    });
  }

  private _createEntityDescriptionGroupDescriptionFormGroup(buildingDescription: EntityDescription): FormGroup<DescriptionFormGroup> {
    return this._formBuilder.group<DescriptionFormGroup>({
      title: this._formBuilder.control<string | null>(buildingDescription.title || null),
      description: this._formBuilder.control<string | null>(buildingDescription.description || null),
    });
  }
}
