import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'eule-logout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
})
export class LogoutComponent {}
