import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DgnbSystem, UsageProfile, UsageProfileType } from '@eeule/eeule-shared/src/types';
import { BehaviorSubject, Subscription, combineLatest, map, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../core/components/base/base.component';
import { IndicatorService } from '../../../core/services/indicator.service';
import { ProjectService } from '../../../core/services/project.service';
import { LifeCyclePhaseEnum } from './../../../enums/LifeCyclePhase.enum';
import { ProjectStatusEnum } from './../../../enums/ProjectStatus.enum';
import { UsageProfileEnum } from './../../../enums/UsageProfile.enum';

interface UsageProfileForm {
  isMainUsage: FormControl<boolean | null>;
  usageProfileType: FormControl<string | null>;
  usageProfile: FormControl<string | null>;
  percentage: FormControl<number | null>;
}
interface NewProjectForm {
  id: FormControl<string | null>;
  lifeCyclePhase: FormControl<string | null>;
  dgnbSystem: FormControl<string | null>;
  name: FormControl<string | null>;
  number: FormControl<string | null>;
  bgfBigger5000: FormControl<boolean | null>;
  bgfSmaller5000: FormControl<boolean | null>;
  circularEconomy: FormControl<boolean | null>;
  nonCircularEconomy: FormControl<boolean | null>;
  withDeconstruction: FormControl<boolean | null>;
  withoutDeconstruction: FormControl<boolean | null>;
  status: FormControl<ProjectStatusEnum | null>;
  hasMixedUsageProfiles: FormControl<boolean | null>;
  hasSubordinateUsage: FormControl<boolean | null>; // untergeordnete Nutzung
  usageProfiles: FormArray<FormGroup<UsageProfileForm>>;
}

@Component({
  selector: 'eule-new-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
  templateUrl: './new-project-dialog.component.html',
  styleUrl: './new-project-dialog.component.scss',
})
export class NewProjectDialogComponent extends BaseComponent implements OnInit {
  public lifeCyclePhaseEnum: typeof LifeCyclePhaseEnum = LifeCyclePhaseEnum;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;
  public dgnbSystems: DgnbSystem[] = [];
  public filteredDgnbSystems: DgnbSystem[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filteredUsageProfiles$: BehaviorSubject<Array<UsageProfile>> = new BehaviorSubject<Array<UsageProfile>>([]);

  public dataForm: FormGroup<NewProjectForm> = this._formBuilder.group({
    id: this._formBuilder.control<string | null>(null),
    lifeCyclePhase: this._formBuilder.control<string | null>(null),
    dgnbSystem: this._formBuilder.control<string | null>(null),
    name: this._formBuilder.control<string | null>(null),
    number: this._formBuilder.control<string | null>(null),
    bgfBigger5000: this._formBuilder.control<boolean>(false),
    bgfSmaller5000: this._formBuilder.control<boolean>(true), // Always opposite value of bgfBigger5000
    circularEconomy: this._formBuilder.control<boolean>(false),
    nonCircularEconomy: this._formBuilder.control<boolean>(true), // Always opposite value of circularEconomy
    withDeconstruction: this._formBuilder.control<boolean>(false),
    withoutDeconstruction: this._formBuilder.control<boolean>(true), // Always opposite value of withDeconstruction // FIXME: temporarily a copy of circularEconomy / nonCircularEconomy
    status: this._formBuilder.control<ProjectStatusEnum | null>(null),
    hasMixedUsageProfiles: this._formBuilder.control<boolean>(false, [Validators.required]), // Mischnutzung
    hasSubordinateUsage: this._formBuilder.control<boolean>(false, [Validators.required]), // Untergeordnete Nutzung
    usageProfiles: this._formBuilder.array<FormGroup<UsageProfileForm>>([
      this._formBuilder.group<UsageProfileForm>({
        isMainUsage: this._formBuilder.control<boolean>(true),
        usageProfileType: this._formBuilder.control<UsageProfileType>('mainUsage'),
        usageProfile: this._formBuilder.control(null),
        percentage: this._formBuilder.control<number>(0),
      }),
    ]),
  });

  private _usageProfilesSubscriptions: Array<Subscription> = [];

  get usageProfilesArray(): FormArray<FormGroup> {
    return this.dataForm.get('usageProfiles') as FormArray;
  }
  get subordinateUsageProfilesArray(): FormArray<FormGroup> {
    const subs: FormArray<FormGroup> = new FormArray<FormGroup>([]);
    for (let index = 0; index < this.usageProfilesArray.value.length; index++) {
      if (this.usageProfilesArray.at(index).get('usageProfileType')?.value === 'subordinateUsage') {
        subs.push(this.usageProfilesArray.at(index));
      }
    }
    return subs;
  }
  get sideUsageProfilesArray(): FormArray<FormGroup> {
    const sides: FormArray<FormGroup> = new FormArray<FormGroup>([]);
    for (let index = 0; index < this.usageProfilesArray.value.length; index++) {
      if (this.usageProfilesArray.at(index).get('usageProfileType')?.value === 'sideUsage') {
        sides.push(this.usageProfilesArray.at(index));
      }
    }
    return sides;
  }

  getUsageProfileFormGroup(index: number): FormGroup {
    return (this.dataForm.get('usageProfiles') as FormArray).controls.at(index) as FormGroup;
  }
  getSubordinateUsageProfileFormGroup(index: number): FormGroup {
    return this.subordinateUsageProfilesArray.controls.at(index) as FormGroup;
  }
  getSideUsageProfileFormGroup(index: number): FormGroup {
    return this.sideUsageProfilesArray.controls.at(index) as FormGroup;
  }

  public constructor(private _formBuilder: FormBuilder, public projectService: ProjectService, private _indicatorService: IndicatorService) {
    super();
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this._indicatorService
      .getAllDngbSystems()
      .pipe(
        map(systems => {
          return systems.map(system => {
            return { ...system.data(), id: system.id } as DgnbSystem;
          });
        }),
        tap((dgnbSystems: DgnbSystem[]) => {
          this.dgnbSystems = dgnbSystems;
          this.dataForm = this._buildFormFromData();
          this._setupDgnbSystemListener();
        })
      )
      .subscribe(() => {
        this.isLoading$.next(false);
      });
  }

  public filterDgnbSystems(phase: LifeCyclePhaseEnum) {
    this.filteredDgnbSystems = this.dgnbSystems
      .filter(system => system.lifeCyclePhase === phase)
      // only in develop environment show non-default-systems (for developing and testing reasons)
      .filter(system => (environment.stage === 'develop' ? system : system.defaultSystem));
  }

  public addSubordinateUsageProfile() {
    this.usageProfilesArray.insert(
      1,
      this._formBuilder.group<UsageProfileForm>({
        isMainUsage: this._formBuilder.control<boolean>(false),
        usageProfileType: this._formBuilder.control<string>('subordinateUsage'),
        usageProfile: this._formBuilder.control(null, Validators.required),
        // Attention: a UsageProfile of type subordinateUsage has no percentages
        percentage: this._formBuilder.control<number | null>(null),
      })
    );
  }
  public addMixedUsageProfile() {
    this.usageProfilesArray.push(
      this._formBuilder.group<UsageProfileForm>({
        isMainUsage: this._formBuilder.control(false),
        usageProfileType: this._formBuilder.control<UsageProfileType>('sideUsage'),
        usageProfile: this._formBuilder.control(null, Validators.required),
        percentage: this._formBuilder.control(0),
      })
    );
  }

  public removeAllSubordinateUsageProfiles() {
    const size: number = this.usageProfilesArray.value.length;
    for (let index = 0; index < size; index++) {
      const controlToRemove = this.usageProfilesArray.controls.find(control => control.get('usageProfileType')?.value === 'subordinateUsage');
      const indexOfControlToRemove = controlToRemove && this.usageProfilesArray.controls.indexOf(controlToRemove);
      indexOfControlToRemove && this.usageProfilesArray.removeAt(indexOfControlToRemove);
    }
  }
  public removeAllSideUsageProfiles() {
    const size: number = this.usageProfilesArray.value.length;
    for (let index = 0; index < size; index++) {
      const controlToRemove = this.usageProfilesArray.controls.find((control: FormGroup) => control.get('usageProfileType')?.value === 'sideUsage');
      const indexOfControlToRemove = controlToRemove && this.usageProfilesArray.controls.indexOf(controlToRemove);
      indexOfControlToRemove && this.usageProfilesArray.removeAt(indexOfControlToRemove);
    }
  }

  public toggleSubordinateUsageProfiles(event: MatSlideToggleChange) {
    if (event.checked === true) {
      this.addSubordinateUsageProfile();
    }
    if (event.checked === false) {
      this.removeAllSubordinateUsageProfiles();
    }
  }
  public toggleMixedUsageProfiles(event: MatSlideToggleChange) {
    if (event.checked === true) {
      this.addMixedUsageProfile();
    }
    if (event.checked === false) {
      this.removeAllSideUsageProfiles();
    }
  }

  public toggleBgfBigger5000(event: MatSlideToggleChange) {
    this.dataForm.get('bgfSmaller5000')?.setValue(!event.checked);
  }

  public toggleCircularEconomy(event: MatSlideToggleChange) {
    this.dataForm.get('nonCircularEconomy')?.setValue(!event.checked);
    this.dataForm.get('withDeconstruction')?.setValue(event.checked);
    this.dataForm.get('withoutDeconstruction')?.setValue(!event.checked);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _buildFormFromData(data?: { dgnbSystem: string | undefined }): FormGroup<any> {
    const _form = this._formBuilder.group({
      id: this._formBuilder.control(uuidv4()),
      lifeCyclePhase: this._formBuilder.control(null, Validators.required),
      dgnbSystem: this._formBuilder.control(data?.dgnbSystem || null, Validators.required),
      name: this._formBuilder.control(null, Validators.required),
      number: this._formBuilder.control(null),
      bgfBigger5000: this._formBuilder.control(false),
      bgfSmaller5000: this._formBuilder.control(true), // Always opposite value of bgfBigger5000
      circularEconomy: this._formBuilder.control(false),
      nonCircularEconomy: this._formBuilder.control(true), // Always opposite value of circularEconomy
      withDeconstruction: this._formBuilder.control(false),
      withoutDeconstruction: this._formBuilder.control(true), // Always opposite value of withDeconstruction // FIXME: temporarily a copy of circularEconomy / nonCircularEconomy
      status: this._formBuilder.control(ProjectStatusEnum.ACTIVE),
      // Mischnutzung
      hasMixedUsageProfiles: this._formBuilder.control<boolean>(false, [Validators.required]),
      hasSubordinateUsage: this._formBuilder.control<boolean>(false, [Validators.required]), // Untergeordnete Nutzung
      usageProfiles: this._initUsageProfiles(),
    });
    return _form;
  }

  private _setupDgnbSystemListener() {
    combineLatest([this.projectService.usageProfiles$, this.dataForm.get('dgnbSystem')!.valueChanges])
      .pipe(
        tap(([allProfiles, selectedSystemId]: [UsageProfile[], string | null]) => {
          this._filterUsageProfiles(selectedSystemId, allProfiles);
        }),
        takeUntil(this.stop$)
      )
      .subscribe(() => {
        // reset usageProfiles on system change
        this.dataForm.get('hasMixedUsageProfiles')?.setValue(false);
        this.dataForm.setControl('usageProfiles', this._initUsageProfiles());
        this._setupValidityUpdater();
      });
  }

  private _initUsageProfiles(): FormArray<FormGroup<UsageProfileForm>> {
    return this._formBuilder.array<FormGroup<UsageProfileForm>>([
      this._formBuilder.group<UsageProfileForm>({
        isMainUsage: this._formBuilder.control(true),
        usageProfile: this._formBuilder.control({ value: null, disabled: !this.dataForm.get('dgnbSystem')!.value }, [Validators.required]),
        usageProfileType: this._formBuilder.control<UsageProfileType>('mainUsage'),
        percentage: this._formBuilder.control(0),
      }),
    ]);
  }

  private _filterUsageProfiles(selectedSystemId: string | null, allProfiles: Array<UsageProfile>) {
    const system: DgnbSystem | undefined = this.dgnbSystems.find(sys => sys.id === selectedSystemId);
    let _filteredProfiles: UsageProfile[] = [];
    if (system && system.usageProfiles && system.usageProfiles.length !== 0) {
      _filteredProfiles = allProfiles.filter(profileFromPool => system.usageProfiles.find(systemProfileId => systemProfileId === profileFromPool.id));
    }
    this.filteredUsageProfiles$.next(_filteredProfiles);
  }

  /**
   * Whenever the usageProfiles-FormArray changes (usageProfiles are added or removed),
   * a listener is set on every usageProfile in order to update the usageProfiles-FormArrays validity.
   *
   * @private
   *
   * @memberOf NewProjectDialogComponent
   */
  private _setupValidityUpdater() {
    this.usageProfilesArray.valueChanges.subscribe(() => {
      this._usageProfilesSubscriptions.forEach(sub => {
        sub.unsubscribe();
      });
      this._usageProfilesSubscriptions = [];
      this.usageProfilesArray.controls.forEach(control => {
        this._usageProfilesSubscriptions.push(
          control.valueChanges.subscribe(() => {
            this.usageProfilesArray.updateValueAndValidity();
          })
        );
      });
    });
  }
}
