import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Project } from '@eeule/eeule-shared/src/types';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { ProjectService } from '../../../../core/services/project.service';
import { StorageService } from '../../../../core/services/storage.service';
import { Tooltip } from '../project-info-page/project-info-page.component';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { UsageProfileEnum } from '../../../../enums/UsageProfile.enum';

interface TotalForm {
  bgf: FormControl<number | null>;
  bri: FormControl<number | null>;
  nrf: FormControl<number | null>;
  nuf: FormControl<number | null>;
  tf: FormControl<number | null>;
  vf: FormControl<number | null>;
  kgf: FormControl<number | null>;
  adgnb: FormControl<number | null>;
  nfVehicle: FormControl<number | null>;
  vfHallCorridor: FormControl<number | null>;
  percentage: FormControl<number | null>;
}

interface UsageProfileForm {
  isMainUsage: FormControl<boolean | null>;
  usageProfile: FormControl<string | null>;
  bgf: FormControl<number | null>;
  bri: FormControl<number | null>;
  nrf: FormControl<number | null>;
  nuf: FormControl<number | null>;
  tf: FormControl<number | null>;
  vf: FormControl<number | null>;
  kgf: FormControl<number | null>;
  adgnb: FormControl<number | null>;
  nfVehicle: FormControl<number | null>;
  vfHallCorridor: FormControl<number | null>;
  percentage: FormControl<number | null>;
}

@Component({
  selector: 'eule-tab-building-parameters',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  templateUrl: './tab-building-parameters.component.html',
  styleUrl: './tab-building-parameters.component.scss',
})
export class TabBuildingParametersComponent extends BaseComponent implements OnInit {
  @Input({ required: true }) buildingParametersFormGroup!: FormGroup;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

  /**
   * This form holds the combined values of all corresponding useageProfiles values
   *
   * @type {FormGroup}
   * @memberOf ProjectInfoPageComponent
   */
  public totalForm: FormGroup<TotalForm> = this._formBuilder.group({
    bgf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of kgf + nrf
    bri: this._formBuilder.control({ value: 0, disabled: true }),
    nrf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nf + tf + vf
    nuf: this._formBuilder.control({ value: 0, disabled: true }),
    tf: this._formBuilder.control({ value: 0, disabled: true }),
    vf: this._formBuilder.control({ value: 0, disabled: true }),
    kgf: this._formBuilder.control({ value: 0, disabled: true }),
    adgnb: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nfVehicle + vfHallCorridor
    nfVehicle: this._formBuilder.control({ value: 0, disabled: true }),
    vfHallCorridor: this._formBuilder.control({ value: 0, disabled: true }),
    percentage: this._formBuilder.control({ value: 0, disabled: true }),
  });

  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
    sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
    mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
    flaechenberechnungNachDin277: `Flächenberechnung nach DIN 277
    BGF(R) = KGF(R) + NRF(R)    
    NRF(R) = NUF(R) + TF(R) + VF(R)
    BGF                Brutto-Grundfläche
    KGF                Konstruktions-Grundfläche
    NRF                Netto-Raumfläche
    NUF                Nutzungsfläche
    TF                 Technikfläche
    VF                 Verkehrsfläche`,
    bemessungsflaeche: `DGNB Bemessungsfläche
    ADGNB = NUFa - NUF a,7,4 + VFa,9,1
    ADGNB                 DGNB Bemesungsfläche
    NUFa                  Nutzungsfläche nach DIN277
    NUF a,7,4            Fahrzeugabstellfläche nach DIN 277
    VFa,9,1                Verkehrsfläche Flure und Hallen nach DIN277
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  get usageProfiles(): FormArray {
    return this.buildingParametersFormGroup.get('usageProfiles') as FormArray;
  }

  public constructor(public projectService: ProjectService, private _storageService: StorageService, private _formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.projectService.project$
      .pipe(
        switchMap(() => this.projectService.projectUser$),
        debounceTime(200),
        tap(user => {
          if (user) {
            this._setListenerAndCalculateCombinedValues();
            this._calculateTotalForm();
          }
        })
      )
      .subscribe();
  }

  public getUsageProfilesFormGroupAt(index: number): FormGroup {
    return this.usageProfiles.at(index) as FormGroup;
  }

  private _setListenerAndCalculateCombinedValues() {
    for (let index = 0; index < this.usageProfiles.controls.length; index++) {
      const group: FormGroup<UsageProfileForm> = this.usageProfiles.controls[index] as FormGroup<UsageProfileForm>;

      // set percentage
      this.totalForm
        .get('adgnb')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          this._recalculatePercentages();
        });

      ///////////////////////// set bgf
      group
        .get('nrf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nrf')!.value) + Number(group.get('kgf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('bgf')!.setValue(_roundedSum);
        });
      group
        .get('kgf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nrf')!.value) + Number(group.get('kgf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('bgf')!.setValue(_roundedSum);
        });
      ///////////////////////// set nrf
      group
        .get('nuf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      group
        .get('tf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      group
        .get('vf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      ///////////////////////// set adgnb
      group
        .get('nuf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
      group
        .get('nfVehicle')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
      group
        .get('vfHallCorridor')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
    }

    // set TotalForm and calculate all UsageProfiles Percentages
    this.usageProfiles.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
      this._calculateTotalForm();
    });
  }

  private _calculateTotalForm() {
    // TODO: use one variable and iterate over all fields and use dynamic name to get single fields and set total field
    let _sumBgf: number = 0;
    let _sumBri: number = 0;
    let _sumNrf: number = 0;
    let _sumNuf: number = 0;
    let _sumTf: number = 0;
    let _sumVf: number = 0;
    let _sumKgf: number = 0;
    let _sumAdgnb: number = 0;
    let _sumNfVehicle: number = 0;
    let _sumVfHallCorridor: number = 0;
    let _sumpercentage: number = 0;
    this.usageProfiles.controls.forEach(profileFormGroup => {
      _sumBgf += Number(profileFormGroup.get('bgf')!.value);
      _sumBri += Number(profileFormGroup.get('bri')!.value);
      _sumNrf += Number(profileFormGroup.get('nrf')!.value);
      _sumNuf += Number(profileFormGroup.get('nuf')!.value);
      _sumTf += Number(profileFormGroup.get('tf')!.value);
      _sumVf += Number(profileFormGroup.get('vf')!.value);
      _sumKgf += Number(profileFormGroup.get('kgf')!.value);
      _sumAdgnb += Number(profileFormGroup.get('adgnb')!.value);
      _sumNfVehicle += Number(profileFormGroup.get('nfVehicle')!.value);
      _sumVfHallCorridor += Number(profileFormGroup.get('vfHallCorridor')!.value);
      _sumpercentage += Number(profileFormGroup.get('percentage')!.value);
    });
    this.totalForm.get('bgf')!.setValue(Math.round(_sumBgf * 100) / 100);
    this.totalForm.get('bri')!.setValue(Math.round(_sumBri * 100) / 100, { emitEvent: false });
    this.totalForm.get('nrf')!.setValue(Math.round(_sumNrf * 100) / 100, { emitEvent: false });
    this.totalForm.get('nuf')!.setValue(Math.round(_sumNuf * 100) / 100, { emitEvent: false });
    this.totalForm.get('tf')!.setValue(Math.round(_sumTf * 100) / 100, { emitEvent: false });
    this.totalForm.get('vf')!.setValue(Math.round(_sumVf * 100) / 100, { emitEvent: false });
    this.totalForm.get('kgf')!.setValue(Math.round(_sumKgf * 100) / 100, { emitEvent: false });
    this.totalForm.get('adgnb')!.setValue(Math.round(_sumAdgnb * 100) / 100);
    this.totalForm.get('nfVehicle')!.setValue(Math.round(_sumNfVehicle * 100) / 100, { emitEvent: false });
    this.totalForm.get('vfHallCorridor')!.setValue(Math.round(_sumVfHallCorridor * 100) / 100, { emitEvent: false });
    this.totalForm.get('percentage')!.setValue(Math.round(_sumpercentage * 100) / 100, { emitEvent: false });
  }

  private _recalculatePercentages() {
    for (let index = 0; index < this.usageProfiles.controls.length; index++) {
      const group: FormGroup<UsageProfileForm> = this.usageProfiles.controls[index] as FormGroup<UsageProfileForm>;
      if (Number(this.totalForm.get('adgnb')!.value) === 0) {
        group.get('percentage')!.setValue(0);
      } else {
        const _sum: number = (Number(group.get('adgnb')!.value) / Number(this.totalForm.get('adgnb')!.value)) * 100;
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        group.get('percentage')!.setValue(_roundedSum);
      }
    }
  }
}
