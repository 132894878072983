import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseComponent } from '../../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { ProjectService } from '../../../core/services/project.service';

export type ScenarioForm = {
  scenario: FormControl<string | null>;
};

@Component({
  selector: 'eule-audit-start-page',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    GeneralTitleComponent,
    SearchBarComponent,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
  ],
  templateUrl: './audit-start-page.component.html',
  styleUrl: './audit-start-page.component.scss',
})
export class AuditStartPageComponent extends BaseComponent {
  /** Form group for selecting pre-check scenario */
  scenarioForm: FormGroup<ScenarioForm> = this._formBuilder.group({
    scenario: [''],
  });
  // TODO: has to be discussed (differentiation between preCheck and audit)
  // scenarioOptions$: Observable<MatSelectOption[]> = this._projectService.retrieveScenariosAsMatOptionsAndSelect();

  constructor(private _formBuilder: FormBuilder, public _projectService: ProjectService) {
    super();
  }

  public scenario() {}
}
