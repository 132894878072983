<!-- TOTAL Parameters of all UsageProfiles -->
<ng-container [formGroup]="buildingParametersFormGroup">
  <div class="col">
    <div class="row">
      <h2 class="bordered default-border-color">Allgemein</h2>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl Geschosse</mat-label>
        <eule-comma-decimal-input formControlName="numberOfFloors"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl Untergeschosse</mat-label>
        <eule-comma-decimal-input formControlName="numberOfUndergroundFloors"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl PKW-Stellplätze</mat-label>
        <eule-comma-decimal-input formControlName="numberOfParkingSpaces"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl Wohneinheiten</mat-label>
        <eule-comma-decimal-input formControlName="numberOfResidentialUnits"></eule-comma-decimal-input>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl Arbeitsplätze</mat-label>
        <eule-comma-decimal-input formControlName="numberOfWorkspaces"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Anzahl Nutzer</mat-label>
        <eule-comma-decimal-input formControlName="numberOfUsers"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>BWZ-Nr.</mat-label>
        <input matInput formControlName="bwzNr" />
      </mat-form-field>
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>LCA-Klasse</mat-label>
        <input matInput formControlName="lcaClass" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Art der Gewerbeflächen</mat-label>
        <input matInput formControlName="typeOfCommercialSpaces" />
      </mat-form-field>
    </div>
    <ng-container [formGroup]="totalForm">
      <h2 class="bordered default-border-color">Bemessungsgrößen</h2>
      <h3 class="bordered default-border-color">Gesamt</h3>
      <div class="row">
        <h4>Flächennutzung nach DIN277</h4>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BGF (R)</mat-label>
          <eule-comma-decimal-input formControlName="bgf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BRI</mat-label>
          <eule-comma-decimal-input formControlName="bri"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NRF (R)</mat-label>
          <eule-comma-decimal-input formControlName="nrf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NUF (R)</mat-label>
          <eule-comma-decimal-input formControlName="nuf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>TF (R)</mat-label>
          <eule-comma-decimal-input formControlName="tf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF (R)</mat-label>
          <eule-comma-decimal-input formControlName="vf"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>KGF (R)</mat-label>
          <input matInput formControlName="kgf" />
        </mat-form-field>
      </div>
      <div class="row">
        <h3>DGNB-Bemessungsfläche</h3>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>ADGNB (R)</mat-label>
          <eule-comma-decimal-input formControlName="adgnb"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NF Fahrzeug</mat-label>
          <eule-comma-decimal-input formControlName="nfVehicle"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF Hallen,Flure</mat-label>
          <eule-comma-decimal-input formControlName="vfHallCorridor"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anteil in %</mat-label>
          <eule-comma-decimal-input formControlName="percentage"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <!-- UsageProfiles Parameters -->
  <ng-container formArrayName="usageProfiles">
    @for (profile of usageProfiles.controls; track profile) {
    <ng-container [formGroup]="getUsageProfilesFormGroupAt($index)">
      <div class="col">
        @if ($index === 0) {
        <h2 class="bordered default-border-color">Hauptnutzung</h2>
        } @else {
        <h2 class="bordered default-border-color">Nebennutzung</h2>
        } @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === profile.get('usageProfile')!.value) {
        {{ usageProfileEnum[profileType.name] }}

        <div class="row">
          <h4>Flächennutzung nach DIN277</h4>
          <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>BGF (R)</mat-label>
            <eule-comma-decimal-input formControlName="bgf"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>BRI</mat-label>
            <eule-comma-decimal-input formControlName="bri"></eule-comma-decimal-input>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NRF (R)</mat-label>
            <eule-comma-decimal-input formControlName="nrf"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NUF (R)</mat-label>
            <eule-comma-decimal-input formControlName="nuf"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>TF (R)</mat-label>
            <eule-comma-decimal-input formControlName="tf"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>VF (R)</mat-label>
            <eule-comma-decimal-input formControlName="vf"></eule-comma-decimal-input>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>KGF (R)</mat-label>
            <eule-comma-decimal-input formControlName="kgf"></eule-comma-decimal-input>
          </mat-form-field>
        </div>
        <div class="row">
          <h3>DGNB-Bemessungsfläche</h3>
          <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>ADGNB (R)</mat-label>
            <eule-comma-decimal-input formControlName="adgnb"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NF Fahrzeug</mat-label>
            <eule-comma-decimal-input formControlName="nfVehicle"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>VF Hallen,Flure</mat-label>
            <eule-comma-decimal-input formControlName="vfHallCorridor"></eule-comma-decimal-input>
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>Anteil in %</mat-label>
            <eule-comma-decimal-input formControlName="percentage"></eule-comma-decimal-input>
          </mat-form-field>
        </div>
        } }
      </div>
    </ng-container>
    }
  </ng-container>
</ng-container>
