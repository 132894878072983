import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { UsageProfileGroupComponent } from './usage-profiles-group/usage-profiles-group.component';

@Component({
  selector: 'eule-tab-usage-profiles',
  standalone: true,
  imports: [UsageProfileGroupComponent],
  templateUrl: './tab-usage-profiles.component.html',
  styleUrl: './tab-usage-profiles.component.scss',
})
export class TabUsageProfilesComponent {
  @Input({ required: true }) usageProfilesFormArray!: FormArray;

  public constructor() {}
}
