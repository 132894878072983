import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommaDecimalInputComponent } from '../../../../../core/components/decimal-comma/decimal-comma.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { AttestationFormGroup, DescriptionFormGroup, DescriptionGroupFormGroup } from '../../project-info-page/project-info-page.component';
import { UsageProfileEnum } from '../../../../../enums/UsageProfile.enum';
import { ProjectService } from '../../../../../core/services/project.service';

@Component({
  selector: 'eule-usage-profiles-group',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatCheckbox,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './usage-profiles-group.component.html',
  styleUrl: './usage-profiles-group.component.scss',
})
export class UsageProfileGroupComponent {
  @Input() usageProfileFormGroup!: any | FormGroup<DescriptionGroupFormGroup>;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

  get usageProfileType(): FormGroup<string | null> {
    return this.usageProfileFormGroup.get('usageProfile');
  }
  get descriptionGroup(): FormGroup<string | null> {
    return this.usageProfileFormGroup.get('descriptionGroup');
  }
  get title(): string {
    return this.descriptionGroup?.get('title')?.value || '';
  }
  get attestations(): FormArray<FormGroup<AttestationFormGroup>> {
    return (this.descriptionGroup?.get('attestations') as FormArray<FormGroup<AttestationFormGroup>>) || new FormArray([]);
  }
  get descriptions(): FormArray<FormGroup<DescriptionFormGroup>> {
    return (this.descriptionGroup?.get('descriptions') as FormArray<FormGroup<DescriptionFormGroup>>) || new FormArray([]);
  }

  public constructor(public projectService: ProjectService) {}

  public getAttestationGroupAt(index: number): FormGroup<AttestationFormGroup> {
    return this.attestations.at(index);
  }
  public getDescriptionGroupAt(index: number): FormGroup<DescriptionFormGroup> {
    return this.descriptions.at(index);
  }
  public getDescriptionTitleAt(index: number): string {
    return this.descriptions.at(index)!.get('title')?.value || '';
  }
  public getAttestationDescriptionAt(index: number): string {
    return this.attestations.at(index)!.get('description')?.value || '';
  }
}
