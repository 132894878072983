import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { MixedUsageProfile, Pagination, Project, ProjectData, User } from '@eeule/eeule-shared/src/types/index';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { BehaviorSubject, Observable, catchError, combineLatest, delay, forkJoin, map, mergeMap, of, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../core/components/search-bar/search-bar.component';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { ProjectService } from '../../core/services/project.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { ProjectUserDisplay, UserService } from '../../core/services/user.service';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { NewProjectDialogComponent } from '../components/new-project-dialog/new-project-dialog.component';
import { NewProjectHelperService } from '../services/new-project-helper.service';
import { DgnbSystem } from '@eeule/eeule-shared/src/types';
import { TypeSafeMatCellDef } from '../../core/directives/TypeSafeMatCellDef';

export type ProjectTableData = Project & { usageProfileKeys: UsageProfileEnum[] };

@Component({
  selector: 'eule-project-list',
  standalone: true,
  imports: [
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBarComponent,
    MatCardModule,
    MatTooltip,
    TypeSafeMatCellDef,
  ],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent extends BaseComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['name', 'number', 'systemType', 'projectOwner', 'date', 'addressCity', 'usageProfiles'];
  public dataSource: MatTableDataSource<ProjectTableData> = new MatTableDataSource<ProjectTableData>();
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterValue: string = '';

  protected readonly getUsageProfileEnumValue = getUsageProfileEnumValue;

  private _authUserId: string | undefined = this._authService.getAuth().currentUser?.uid;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    private _router: Router,
    private _newProjectDialog: MatDialog,
    public projectService: ProjectService,
    public userService: UserService,
    private _indicatorService: IndicatorService,
    private _usageProfilesService: UsageProfileService,
    private _snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private _newProjectHelperService: NewProjectHelperService,
    private _authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this._loadProjects();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  private _loadProjects() {
    if (this._authUserId) {
      this.isLoading$.next(true);

      const userProjects$: Observable<Project[]> = this.userService.getUser(this._authUserId).pipe(
        switchMap((user: User) => {
          if (user.projectIds?.length) {
            return this.projectService.getProjectsByIds(user.projectIds);
          } else {
            return of(null);
          }
        }),
        map((projectsSnaps: QuerySnapshot<unknown, DocumentData> | null) => {
          if (projectsSnaps) {
            return projectsSnaps.docs.map(projectSnap => projectSnap.data() as Project);
          } else {
            return [];
          }
        }),
        // read project User Data for displaying in List
        mergeMap((projects: Project[]) => {
          const arr = projects.map(proj =>
            this.projectService.getProjectUserDisplay(proj.id, proj.projectOwner!).pipe(
              map((user: ProjectUserDisplay) => {
                if (user?.firstName && user?.lastName) {
                  return { ...proj, projectOwner: `${user.firstName} ${user.lastName}` };
                }
                if (user?.email) {
                  return { ...proj, projectOwner: user.email };
                }
                return { ...proj, projectOwner: '---' }; // only for display purposes
              })
            )
          );

          if (arr.length) {
            return forkJoin(arr);
          } else {
            return of([]);
          }
        })
      );

      combineLatest([
        userProjects$.pipe(
          mergeMap(projects => {
            const arr: Observable<DgnbSystem>[] = projects
              .filter(project => project.dgnbSystem?.length)
              .map(proj => {
                return this._indicatorService.getDngbSystem(proj.dgnbSystem);
              });
            return forkJoin(arr).pipe(
              map(dgnbSystems => {
                return projects.map(project => {
                  return {
                    ...project,
                    resolvedDgnbSystem: dgnbSystems.find(dgnbSystem => dgnbSystem.id === project.dgnbSystem),
                  };
                });
              })
            );
          })
        ),
        this._usageProfilesService.getUsageProfiles(),
      ])
        .pipe(
          catchError(error => {
            this._snackbarService.showErrorMessage('Beim Initialisieren der Daten ist ein Fehler aufgetreten.');
            return throwError(() => error);
          }),
          takeUntil(this.stop$)
        )
        .subscribe(([projects, usageProfiles]) => {
          this.dataSource.data =
            projects.map(project => {
              return {
                ...project,
                usageProfileKeys:
                  usageProfiles.data
                    ?.filter(up => {
                      return project.usageProfiles.map(pup => pup.usageProfile).some(pup => pup === up.id);
                    })
                    .map(up => up.name as UsageProfileEnum) || [],
              };
            }) || [];
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading$.next(false);
        });
    } else throw new Error('Auth User not found');
  }

  public newProject(): void {
    this.analyticsService.sendEvent('button_click', { label: 'project-list_newProject' });
    const dialogRef = this._newProjectDialog.open<NewProjectDialogComponent, Project, Project | undefined>(NewProjectDialogComponent, {
      width: '40vw',
      maxWidth: '40vw',
      height: '80vh',
    });

    dialogRef.afterClosed().subscribe((newProjectDialogResult: Project | undefined) => {
      if (!newProjectDialogResult) {
        return;
      }
      if (!newProjectDialogResult.dgnbSystem) {
        throw new Error('Project DGNB System not set');
      }
      if (!newProjectDialogResult.id) {
        throw new Error('Project ID not set');
      }
      if (newProjectDialogResult.bgfBigger5000 === newProjectDialogResult.bgfSmaller5000) {
        throw new Error('bgfBigger5000 and bgfSmaller5000 have the same value. One must be true, the other false');
      }
      if (newProjectDialogResult.withDeconstruction === newProjectDialogResult.withoutDeconstruction) {
        throw new Error('withDeconstruction and withoutDeconstruction have the same value. One must be true, the other false');
      }

      if (newProjectDialogResult && this._authUserId) {
        this.isLoading$.next(true);
        const projectOwnerId: string = uuidv4();
        const _project: Project = {
          ...newProjectDialogResult,
          buildingDescriptionGroups: this._newProjectHelperService.getBuildingDescriptionGroups(),
          usageProfiles: newProjectDialogResult.usageProfiles.map((profile: MixedUsageProfile) =>
            this._newProjectHelperService.getUsageProfileWithDescriptionGroup(profile)
          ),
          certificationDescriptionGroups: this._newProjectHelperService.getCertificationDescriptionGroups(),
          imagesDescriptionGroups: this._newProjectHelperService.getImagesDescriptionGroups(),
          projectOwner: projectOwnerId,
          leistungsPhasen: new Array(9).fill(null),
          qng: false,
          euTaxonomy: false,
        };

        this.projectService
          .createProject(_project)
          .pipe(
            switchMap(() => this.projectService.addUserToProject(newProjectDialogResult.id, this._authUserId!, projectOwnerId)),
            map(() => this.userService.addProjectToUser(newProjectDialogResult.id, this._authUserId!)),
            tap(() =>
              this._indicatorService.cloud_copyDngbSystemToProjectPreCheck(
                `dgnbSystems/${newProjectDialogResult.dgnbSystem}`,
                `projects/${newProjectDialogResult.id}/preCheckScenarios/${uuidv4()}`
              )
            ),
            delay(1000),
            tap(() => {
              this._loadProjects();
              this.isLoading$.next(false);
            }),
            catchError(error => {
              this.isLoading$.next(false);
              throw new Error('Error:', error);
            })
          )
          .subscribe();
      }
    });
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    if (this.dataSource) {
      this.dataSource.filter = _filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      } else {
        new Error('No DataSource for Table');
      }
    }
  }

  public clickProjekt(project: ProjectData) {
    this._router.navigate(['/intern/project', project.id]).catch(error => console.error(error));
  }
}
