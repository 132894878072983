<div id="profile-information" class="smaller-padding flexbox centered">
  <div [formGroup]="profileForm" class="col">
    @if (editMode) {
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>Position</mat-label>
        <input matInput formControlName="title" data-cy="input-profile-information-title" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>Vorname</mat-label>
        <input matInput formControlName="firstName" data-cy="input-profile-information-firstName" />
        @if (profileForm.get('firstName')?.hasError('minlength')) {
        <mat-error>Mindestlänge nicht erreicht</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>Nachname</mat-label>
        <input matInput formControlName="lastName" data-cy="input-profile-information-lastName" />
        @if (profileForm.get('lastName')?.hasError('minlength')) {
        <mat-error>Mindestlänge nicht erreicht</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>eMail</mat-label>
        <input matInput formControlName="email" data-cy="input-profile-information-email" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>Firma</mat-label>
        <input matInput formControlName="company" data-cy="input-profile-information-company" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent">
        <mat-label>Telefon</mat-label>
        <input matInput formControlName="phone" data-cy="input-profile-information-phone" />
      </mat-form-field>
    </div>
    <button
      mat-raised-button
      color="primary"
      [disabled]="profileForm.invalid"
      (click)="save()"
      class="action-button no-shadow"
      data-cy="button-profile-information-save"
    >
      <mat-icon>save</mat-icon>
      Speichern
    </button>

    } @else {
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">Position:</div>
      <div>{{ (userService.euleUser$ | async)?.title }}</div>
    </div>
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">Vorname:</div>
      <div>{{ (userService.euleUser$ | async)?.firstName }}</div>
    </div>
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">Nachname:</div>
      <div>{{ (userService.euleUser$ | async)?.lastName }}</div>
    </div>
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">E-Mail:</div>
      <div>{{ (userService.euleUser$ | async)?.email }}</div>
    </div>
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">Firma:</div>
      <div>{{ (userService.euleUser$ | async)?.company }}</div>
    </div>
    <div class="flexbox align-center xs-gap">
      <div class="user-info-label">Telefon:</div>
      <div>{{ (userService.euleUser$ | async)?.phone }}</div>
    </div>
    <button mat-raised-button color="primary" (click)="edit()" class="action-button no-shadow" data-cy="button-profile-information-edit">
      <mat-icon>edit</mat-icon>
      Bearbeiten
    </button>
    }
  </div>
</div>
