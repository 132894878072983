import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BuildingDescriptionGroupComponent } from './building-description-group/building-description-group.component';
import { TotalDescriptionGroupsFormGroup } from '../project-info-page/project-info-page.component';

@Component({
  selector: 'eule-tab-building-description',
  standalone: true,
  imports: [BuildingDescriptionGroupComponent],
  templateUrl: './tab-building-description.component.html',
  styleUrl: './tab-building-description.component.scss',
})
export class TabBuildingDescriptionComponent {
  @Input({ required: true }) totalBuildingDescriptionFormGroup!: FormGroup<TotalDescriptionGroupsFormGroup>;

  get buildingDescriptionFormArray() {
    return this.totalBuildingDescriptionFormGroup.get('buildingDescriptionGroups') as FormArray;
  }
}
