import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'eule-comma-decimal-input',
  templateUrl: './decimal-comma.component.html',
  styleUrl: './decimal-comma.component.scss',
  standalone: true,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, MatFormFieldModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommaDecimalInputComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => CommaDecimalInputComponent),
    },
  ],
})
export class CommaDecimalInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, AfterViewInit {
  static nextId = 0;
  private _value: string = '';
  private _placeholder: string = '';
  private _required: boolean = false;
  private _disabled: boolean = false;
  private _focused: boolean = false;
  private _stateChanges = new Subject<void>();
  private _controlType = 'comma-decimal-input';
  private _id = `comma-decimal-input-${CommaDecimalInputComponent.nextId++}`;
  private _describedBy = '';

  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  constructor(private cd: ChangeDetectorRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // Falls `disabled` bereits gesetzt wurde, wird hier sichergestellt, dass es angewendet wird
    this.setDisabledState(this._disabled);
  }

  // Implementierung von MatFormFieldControl
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    const convertedValue = value.replace(',', '.'); // Internen Wert konvertieren
    if (convertedValue !== this._value) {
      this._value = convertedValue;
      this.onChange(this._value);
      this._stateChanges.next();
    }
  }

  get focused(): boolean {
    return this._focused;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get stateChanges(): Observable<void> {
    return this._stateChanges;
  }

  get id(): string {
    return this._id;
  }

  get describedBy(): string {
    return this._describedBy;
  }

  get controlType(): string {
    return this._controlType;
  }

  get empty(): boolean {
    return !this._value;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    if (this.inputElement) {
      this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this._value = value ? value.toString() : '';
    this.cd.markForCheck(); // ChangeDetectorRef verwenden
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    if (this.inputElement) {
      this.inputElement.nativeElement.disabled = isDisabled;
    }
  }

  focus(): void {
    this.inputElement.nativeElement.focus();
    this._focused = true;
    this._stateChanges.next();
  }

  blur(): void {
    this._focused = false;
    this._stateChanges.next();
    this.onTouched(); // Signalisiert, dass das Feld berührt wurde
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const sanitizedValue = this.sanitizeValue(value); // Werte sanitieren
    this._value = sanitizedValue.replace(',', '.'); // Internen Wert konvertieren
    input.value = sanitizedValue; // Update the display value after sanitization
    this.onChange(this._value);
    this._stateChanges.next();
  }

  onContainerClick(): void {
    this.focus();
  }

  onTouched: () => void = () => {
    this.blur();
  };

  onChange: (value: string) => void = () => {};

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }

  setDescribedByIds(ids: string[]): void {
    this._describedBy = ids.join(' ');
  }

  // Implementierung von MatFormFieldControl spezifischen Properties
  get ngControl(): NgControl | null {
    return null; // Hier könnte eine FormControl zurückgegeben werden, falls verfügbar
  }

  get errorState(): boolean {
    // Implementiere Fehlerüberprüfung hier, falls nötig
    return false;
  }

  private sanitizeValue(value: string): string {
    // Erlaubt nur gültige Zahlen mit bis zu einem Dezimaltrennzeichen (Komma)
    const cleanValue = value
      .replace(/[^0-9,]/g, '') // Entfernt ungültige Zeichen
      .replace(/(?!^),/, ',') // Erlaubt nur ein Komma
      .replace(/(\..*|,.*[,])/, ''); // Entfernt Punkte und doppelte Kommas

    // Überprüft, ob nur ein Dezimaltrennzeichen vorhanden ist
    const parts = cleanValue.split(',');
    if (parts.length > 2) {
      return this._value; // Rückgabewert bleibt unverändert, wenn nicht gültig
    }

    return cleanValue;
  }

  get displayValue(): string {
    return this._value.replace('.', ','); // Zeigt den Wert mit Komma an
  }
}
