import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ImagesDescriptionGroupComponent } from './images-description-group/images-description-group.component';

@Component({
  selector: 'eule-tab-images',
  standalone: true,
  imports: [ImagesDescriptionGroupComponent],
  templateUrl: './tab-images.component.html',
  styleUrl: './tab-images.component.scss',
})
export class TabImagesComponent {
  @Input({ required: true }) totalImagesDescriptionFormGroup!: FormGroup;

  get imagesDescriptionFormArray() {
    return this.totalImagesDescriptionFormGroup.get('imagesDescriptionGroups') as FormArray;
  }
}
