import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { ProjectService } from '../../../../core/services/project.service';
import { BooleanEnum } from '../../../../enums/BooleanEnum.enum';
import { CertificationVersionEnum } from '../../../../enums/CertificationVersion.enum';
import { LifeCyclePhaseEnum } from '../../../../enums/LifeCyclePhase.enum';
import { UsageProfileEnum } from '../../../../enums/UsageProfile.enum';
import { Tooltip } from '../project-info-page/project-info-page.component';
import { CertificationDescriptionGroupComponent } from './certification-description-group/certification-description-group.component';

@Component({
  selector: 'eule-tab-certification',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
    CertificationDescriptionGroupComponent,
  ],
  templateUrl: './tab-certification.component.html',
  styleUrl: './tab-certification.component.scss',
})
export class TabCertificationComponent {
  @Input({ required: true }) certificationFormGroup!: FormGroup;
  @Input({ required: true }) totalCertificationDescriptionFormGroup!: FormGroup;

  get certificationDescriptionFormArray() {
    return this.totalCertificationDescriptionFormGroup.get('certificationDescriptionGroups') as FormArray;
  }

  public lifeCyclePhaseEnum: typeof LifeCyclePhaseEnum = LifeCyclePhaseEnum;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;
  public certificationVersionEnum: typeof CertificationVersionEnum = CertificationVersionEnum; // FIXME: this is obsolete
  public booleanEnum: typeof BooleanEnum = BooleanEnum;

  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  public constructor(public projectService: ProjectService) {}
}
