<div [formGroup]="contactPersonsFormGroup" class="mat-elevation-z4">
  <div class="col tab-content">
    <mat-form-field>
      <mat-label>Projekteigentümer</mat-label>
      <mat-select formControlName="projectOwner">
        @for (user of projectService.projectUsers$ | async; track user) {
        <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Verantwortlicher</mat-label>
      <!-- FIXME: acts like responsibleId  -->
      <mat-select formControlName="creator">
        @for (user of projectService.projectUsers$ | async; track user) {
        <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Auditor</mat-label>
      <mat-select formControlName="auditor">
        @for (user of projectService.projectUsers$ | async; track user) {
        <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Supporter und externer Prüfer</mat-label>
      <mat-select formControlName="supporter">
        @for (user of projectService.projectUsers$ | async; track user) {
        <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
