import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { AuthService } from '../../services/auth-christian/auth.service';

/**
 * Guard to redirect users if they are authenticated.
 * @returns {Observable<boolean>} An observable indicating whether the route should be activated.
 */
export const redirectGuard: CanActivateFn = (): Observable<boolean> => {
  const _authService: AuthService = inject(AuthService);
  const _router: Router = inject(Router);
  return _authService.isAuthenticated().pipe(
    take(1),
    map(isAuthenticated => {
      if (isAuthenticated) {
        // Redirect to the restricted portal area
        _router.navigate(['/intern']).catch(error => console.error('error navigating to portal:', error));
        return false;
      }
      return true;
    })
  );
};
