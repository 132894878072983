<div class="project-info-page flexbox direction-column">
  <eule-general-title [title]="'Projektinfo'">
    @if ((isLoading$ | async) === false) {
    <div class="flexbox align-center default-gap">
      <button mat-raised-button color="primary" [disabled]="projectForm.invalid" (click)="update()">
        <mat-icon>save</mat-icon>
        Speichern
      </button>
    </div>
    }
  </eule-general-title>

  @if (isLoading$ | async) {
  <mat-spinner class="center"></mat-spinner>
  } @else {
  <mat-card class="custom-card w-100-percent">
    <mat-card-content>
      <!-- @if (!(isProjectLoading$ | async)) { -->
      <div [formGroup]="projectForm" class="container">
        <mat-tab-group dynamicHeight class="w-100-percent">
          <!-- TAB Allgemein -->
          <mat-tab label="Allgemein">
            <eule-tab-general [generalFormGroup]="generalFormGroup" [projectImage$]="projectImage$"></eule-tab-general>
          </mat-tab>

          <!-- Tab Baubeschreibung -->
          <mat-tab label="Baubeschreibung">
            <eule-tab-building-description [totalBuildingDescriptionFormGroup]="totalBuildingDescriptionFormGroup"></eule-tab-building-description>
          </mat-tab>

          <!-- Tab Nutzungsprofile -->
          <mat-tab label="Nutzungsprofile">
            <eule-tab-usage-profiles [usageProfilesFormArray]="usageProfilesFormArray"></eule-tab-usage-profiles>
          </mat-tab>

          <!-- Tab Leistungsphasen -->
          <mat-tab label="Leistungsphasen">
            <eule-tab-leistungsphasen [leistungsPhasenFormGroup]="leistungsPhasenFormGroup"></eule-tab-leistungsphasen>
          </mat-tab>

          <!-- Tab Zertifizierung -->
          <mat-tab label="Zertifizierung">
            <eule-tab-certification
              [certificationFormGroup]="certificationFormGroup"
              [totalCertificationDescriptionFormGroup]="totalCertificationDescriptionFormGroup"
            ></eule-tab-certification>
          </mat-tab>

          <mat-tab label="Gebäudeparameter">
            <eule-tab-building-parameters [buildingParametersFormGroup]="buildingParametersFormGroup"></eule-tab-building-parameters>
          </mat-tab>

          <!-- Tab Ansprechpartner -->
          <mat-tab label="Ansprechpartner">
            <eule-tab-contact-persons [contactPersonsFormGroup]="contactPersonsFormGroup"></eule-tab-contact-persons>
          </mat-tab>

          <!-- Tab Fotos -->
          <mat-tab label="Fotos">
            <eule-tab-images [totalImagesDescriptionFormGroup]="totalImagesDescriptionFormGroup"></eule-tab-images>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- } -->
    </mat-card-content>
  </mat-card>
  }
</div>
